import Vue from 'vue'
import Vuex from 'vuex'

import snackBar from './modules/snack_bar.state'
import auth from './modules/auth.state'
import briefCases from './modules/brief_cases.state'
import deposits from './modules/deposits.state'
import choiceCurrencies from './modules/choise_currencies.state'
import fundCurrencies from './modules/fund_currencies.state'
import exchangerOperations from './modules/exchanger_operations.state'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    visibilitySideBar: false
  },
  getters: {
    getVisibilitySideBar(state) {
      return state.visibilitySideBar
    }
  },
  mutations: {
    setShowSideBar(state, payload) {
      state.visibilitySideBar = payload
    },
    hideSideBar(state) {
      state.visibilitySideBar = false
    },
    showSideBar(state) {
      state.visibilitySideBar = true
    },
    toggleSideBar(state) {
      state.visibilitySideBar = !state.visibilitySideBar
    }
  },
  actions: {
  },
  modules: {
    snackBar,
    auth,
    briefCases,
    deposits,
    choiceCurrencies,
    fundCurrencies,
    exchangerOperations
  }
})
