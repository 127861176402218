<template>
  <div class="s-item-wrapper text--primary">
    <div>
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        icon
        @click="backToList()"
      >
        <v-icon left>mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div class="s-title text-center">Заявка на обмен</div>

    <div
      v-if="loadingCurrencies"
      class="text-center mt-4"
    >

      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div
      v-else
    >
      <v-form
        ref="form"
        v-model="formValid"
      >
        <div class="s-title-label">Отдаете</div>

        <v-row class="mt-2 s-row">
          <v-col>
            <v-autocomplete
              dense
              outlined
              placeholder="Выберите валюту"
              item-text="crypto_name"
              item-value="crypto_id"
              :items="getItems"
              :rules="[v => !!v || 'Выберите валюту']"
              v-model="inCurrencyId"
            >
              <template v-slot:selection="data">
                <v-avatar size="24" left>
                  <v-img :src="data.item.logo"></v-img>
                </v-avatar>
                <div class="ml-2">{{ data.item.crypto_name }}</div>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar size="24">
                    <img :src="data.item.logo">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.crypto_name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="align-center s-row">
          <v-col cols="6" class="s-title-item">Количество</v-col>
          <v-col cols="6">
            <v-text-field
              dense
              :rules="sumRules"
              v-model="sum"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <div class="s-title-label mt-4">Получаете</div>

        <v-row class="mt-2 s-row">
          <v-col>
            <v-autocomplete
              dense
              outlined
              placeholder="Выберите валюту"
              item-text="crypto_name"
              item-value="crypto_id"
              :items="getItems"
              :rules="[v => !!v || 'Выберите валюту']"
              v-model="outCurrencyId"
            >
              <template v-slot:selection="data">
                <v-avatar size="24" left>
                  <v-img :src="data.item.logo"></v-img>
                </v-avatar>
                <div class="ml-2">{{ data.item.crypto_name }}</div>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar size="24">
                    <img :src="data.item.logo">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.crypto_name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="6"
          >
            <v-btn
              x-large
              block
              class="s-desktop-btn-back"
              @click="backToList()"
            >Отмена</v-btn>
          </v-col>
          <v-col cols="12" md="6" class="text-center">
            <v-btn
              :block="$vuetify.breakpoint.mdAndUp"
              x-large
              color="#D1B986"
              :loading="loadingSend"
              @click="sendData"
            >Создать</v-btn>

          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex'

export default {
  name: "CreatePage",
  data: () => ({
    formValid: false,
    loadingCurrencies: false,
    loadingSend: false,
    inCurrencyId: null,
    outCurrencyId: null,
    sum: null,
    sumRules: [
      v => !!v || 'Введите суму',
      v => /^(\d|\.)+$/.test(v) || 'Только числа',
    ]
  }),
  async mounted() {
    this.loadingCurrencies = true
    await this.loadAll()
    this.loadingCurrencies = false
  },
  computed: {
    ...mapGetters('fundCurrencies', ['getItems']),
    ...mapGetters('exchangerOperations', {getOperations: 'getItems'})
  },
  methods: {
    ...mapActions('fundCurrencies', ['loadAll']),
    ...mapActions('exchangerOperations', ['create']),
    ...mapMutations('exchangerOperations', ['setShowBlockName']),

    backToList() {
      let name = this.getOperations.length > 0 ? 'list' : 'empty'
      this.setShowBlockName(name)
    },
    validate () {
      this.$refs.form.validate()
    },
    async sendData() {
      if (this.loadingSend) {
        return
      }

      this.validate()

      if (!this.formValid) {
        return
      }

      this.loadingSend = true

      let data = {
        in_currency_id: this.inCurrencyId,
        out_currency_id: this.outCurrencyId,
        sum: this.sum
      }

      try {
        const response = await this.create(data)
        console.log('response', response)

        this.loadingSend = false

        if (response.status !== 'ok') {
          return
        }

        this.$store.dispatch('snackBar/run', 'Заявка отправлена')
        this.setShowBlockName('list')
      }
      catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.s-item-wrapper {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  max-width: 400px;
  margin: 0 auto;

  .s-title {
    color: $g-color-dark-blue;
    font-size: 18px;
    font-weight: 600;
  }

  .s-row {
    margin-top: -30px;
  }

  .s-title-item {
    color: $g-color-black-main;
    font-size: 16px;
    font-weight: 400;
  }

  .s-desc-item {
    color: $g-color-gray;
    font-size: 14px;
    font-weight: 400;
  }

  .s-desktop-btn-back {
    color: #ffffff;
    background-color: $g-color-dark-blue;
  }
}
.s-title-label {
  font-weight: 400;
  font-size: 18px;
  color: $g-color-black-main
}

</style>
