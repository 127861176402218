<template>
  <div class="s-block">

    <div class="text-right">
      <v-icon
          large
          class="mr-4 mt-4"
          color="white"
          @click="$store.commit('toggleSideBar')"
      >
        mdi-menu
      </v-icon>
    </div>

    <div class="s-content">
      <div>
        <v-row>
          <v-col cols="12" class="d-flex justify-center d-md-none">
            <v-img
                max-width="80"
                max-height="66"
                src="/images/logo.png"
                class="mr-4"
            ></v-img>
            <v-img
              max-width="182"
              max-height="64"
              src="/images/main_title.png"
            ></v-img>
          </v-col>
          <v-col cols="12" class="justify-center align-center d-none d-md-flex">
            <v-img
              max-width="293"
              max-height="40"
              src="/images/bitrade_title_logo.png"
              class="mr-4"
            ></v-img>
            <v-img
              max-width="104"
              max-height="82"
              src="/images/logo_large.png"
              class="mr-4"
            ></v-img>
            <v-img
              max-width="280"
              max-height="42"
              src="/images/capital_title_logo.png"
            ></v-img>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" offset-md="6" class="text-center text-md-left mt-md-10">
            Bitrade Capital - это криптовалютный фонд, осуществляющий профессиональное управление вашим инвестиционным портфелем .
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="d-flex justify-center align-center align-md-start flex-grow-1">
      <v-btn
        large
        :x-large="$vuetify.breakpoint.mdAndUp"
        class="s-btn"
        :to="{name: 'login'}"
      >Инвестировать</v-btn>
    </div>

  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>

  .s-block {
    flex-direction: column;
    display: flex;
    height: calc(100vh - 24px);

    & > div:nth-child(2) {
      flex-grow: 2;
      align-self: center;
    }

    & > .s-block-btn {
      //margin-bottom: 55px;
      align-self: center;
      flex-grow: 1;
    }

    & .s-footer {
      max-height: 100px;
      background-color: #11214A;
      flex-grow: 0;

      div {
        text-align: center;

        span {
          color: #A1A1A1;
          font-size: 14px;
        }
      }

      & div:first-child span {
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 40px;
      }
      & div:last-child span {
        font-weight: 400;
      }
    }
  }

  .s-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .s-logo {
    text-align: center;

    img:first-child {

    }
  }

  .s-btn {
    text-transform: none;
    color: #001242;
    font-weight: bold;
  }
</style>
