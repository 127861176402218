<template>

  <v-chip
    x-small
    label
    :color="color"
    text-color="#ffffff"
  >
    <span class="s-chip-text">{{ text }}</span>
  </v-chip>
</template>

<script>
export default {
  name: "ChipStatus",
  props: ['color', 'text']
}
</script>

<style lang="scss" scoped>

.s-chip-text {

  font-weight: 500;
  font-size: 12px;
}
</style>
