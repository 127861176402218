
export default {
    namespaced: true,
    state: {
        show: false,
        text: null,
    },
    mutations: {
        isShow(state, payload) {
            state.show = payload;
        },
        hide(state) {
            state.show = false;
        },
        setText(state, payload) {
            state.text = payload;
        }
    },
    getters: {
        isShow(state) {
            return state.show;
        },
        getText(state) {
            return state.text;
        }
    },
    actions: {
        run({commit}, text) {
            commit('isShow', true);
            commit('setText', text);
        }
    }
}
