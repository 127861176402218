<template>
  <v-dialog
      max-width="340"
      :value="value"
      @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-title>
        Создать новый портфель
      </v-card-title>


      <v-form
        ref="form"
        v-model="formValid"
        @submit.prevent="create"
      >
        <v-card-text>
          <v-text-field
              dense
              outlined
              placeholder="Введите название портфеля"
              :rules="nameRules"
              v-model="name"
          ></v-text-field>
        </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-space-around">

        <v-btn
            text
            color="red darken-1"
            @click="dialogClose"
        >
          Отменить
        </v-btn>

        <v-divider vertical></v-divider>
        <v-btn
            text
            :loading="loadingCreate"
            type="submit"
        >
          Создать
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogNewBriefCase",
  props: [
      'value'
  ],
  data: () => ({
    loadingCreate: false,
    formValid: false,
    name: 'Потфель 1',
    nameRules: [
      v => !!v || 'Введите название портфеля'
    ]
  }),
  methods: {
    create() {
      if (this.loadingCreate) {
        return
      }

      this.validate()

      if (!this.formValid) {
        return
      }

      const data = {
        name: this.name
      }

      this.loadingCreate = true
      this.$store.dispatch('briefCases/create', data)
      this.loadingCreate = false
      this.dialogClose()
    },
    dialogClose() {
      this.$emit('input', false)
    },
    validate () {
      this.$refs.form.validate()
    },
  }
}
</script>

<style scoped>

</style>
