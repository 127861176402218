<template>
<div>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-text class="pt-4">Спасибо, мы получили Ваше сообщение.</v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="green darken-1"
          text
          @click="successDialog"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <div class="s-item-wrapper text--primary">
    <div>
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        icon
        @click="backToList()"
      >
        <v-icon left>mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div class="s-title text-center">Заявка на депозит</div>

    <div
      v-if="loadingCurrencies"
      class="text-center mt-4"
    >

      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form
        ref="form"
        v-model="formValid"
      >
        <v-row class="mt-2 s-row">
          <v-col>
            <v-autocomplete
              dense
              outlined
              placeholder="Валюта депозита"
              item-text="crypto_name"
              item-value="crypto_id"
              :disabled="disabledFields"
              :items="getItems"
              :rules="[v => !!v || 'Выберите валюту']"
              v-model="fundCurrency"
            >
              <template v-slot:selection="data">
                <v-avatar size="24" left>
                  <v-img :src="data.item.logo"></v-img>
                </v-avatar>
                <div class="ml-2">{{ data.item.crypto_name }}</div>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar size="24">
                    <img :src="data.item.logo">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.crypto_name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row
          v-if="status.name"
          class="align-center s-row mb-2"
        >
          <v-col cols="6" class="s-title-item">Статус</v-col>
          <v-col cols="6" class="text-right">
            <chip-status-component
              :color="status.color"
              :text="status.name"
            ></chip-status-component>
          </v-col>
        </v-row>

        <v-row class="align-center s-row">
          <v-col cols="6" class="s-title-item">Количество</v-col>
          <v-col cols="6">
            <v-text-field
              dense
              :disabled="disabledFields"
              :rules="sumRules"
              v-model="sum"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="align-center s-row">
          <v-col cols="6" class="s-title-item">Срок депозита</v-col>
          <v-col cols="6">
            <v-select
              dense
              :loading="loadingTermList"
              :disabled="disabledFields"
              :rules="[v => !!v || 'Выберите период']"
              :items="years"
              v-model="year"
            ></v-select>
          </v-col>
        </v-row>

        <v-divider class="ma-3"></v-divider>

        <v-row>
          <v-col cols="12" class="s-desc-item">Пожалуйста, в течении 24 часов отправьте с вашего внешнего кошелька монеты на адрес указанный ниже:</v-col>
          <v-col cols="12" class="mt-0">
            <v-text-field
              dense
              readonly
              hide-details
              ref="textToCopy"
              append-outer-icon="mdi-content-copy"
              :value="getAddress()"
              @click:append-outer="copyText"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="s-row">
          <v-col>

            <v-checkbox
              :disabled="disabledFields"
              :rules="[v => !!v || 'Вам нужно принять условие']"
              v-model="agreementTerms"
            >
              <template v-slot:label>
                <div class="s-title-item">Я принимаю условия <a href="#" @click.stop>соглашения</a></div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="6"
        >
          <v-btn
            x-large
            block
            class="s-desktop-btn-back"
            @click="backToList"
          >Отмена</v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
            v-if="showBtnNext"
            x-large
            block
            color="#D1B986"
            :loading="loadingCreate"
            @click="sendData"
          >Далее</v-btn>
          <v-btn
            v-else
            x-large
            block
            color="#D1B986"
            :loading="loadingPaid"
            :disabled="disabledPaidBtn"
            @click="sendAboutPaid"
          >{{ textPaidBtn }}</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
  <div class="mt-3">
    *Внимание! Фиксация курса по отношению к USD, RUB, BTC произойдёт в
    момент поступления средств на ваш личный счёт.
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import Deposit from "../../../entities/Deposit";
import ChipStatusComponent from "../../ChipStatus";

export default {
  name: "DepositItemCreate",
  components: { ChipStatusComponent },
  data: () => ({
    formValid: false,
    dialog: false,
    item: null,
    years: [],
    status: {
      name: null,
      color: null
    },
    sum: null,
    year: null,
    agreementTerms: false,
    disabledFields: false,
    showBtnNext: true,
    loadingCurrencies: false,
    loadingPaid: false,
    loadingCreate: false,
    loadingTermList: false,
    disabledPaidBtn: false,
    sumRules: [
      v => !!v || 'Введите суму',
      v => /^(\d|\.)+$/.test(v) || 'Только числа',
    ]
  }),
  async mounted() {
    await this.initBlock()
  },
  computed: {
    ...mapGetters('fundCurrencies', ['getItems', 'getSelectedItem']),
    ...mapGetters('briefCases', { getBriefCase: 'getSelectedItem'}),
    ...mapGetters('fundCurrencies', { getCurrency: 'getSelectedItem'}),
    ...mapGetters('deposits', { getSelectedDeposit: 'getSelectedItem'}),

    fundCurrency: {
      get() {
        return this.getCurrency
      },
      set(id) {
        this.setSelectedItemById(id)
      }
    }
  },
  methods: {
    ...mapActions('fundCurrencies', ['loadAll', 'setSelectedItemById']),
    ...mapActions('deposits', ['create']),
    ...mapMutations('deposits', ['setShowBlockName']),
    ...mapMutations('deposits', {setSelectedDeposit: 'setSelectedItemById'}),

    getAddress() {
      if (!this.getCurrency) {
        return null
      }

      return this.getCurrency.address
    },
    async initBlock() {
      this.loadingCurrencies = true
      await this.loadAll()
      await this.loadTerms()
      this.initFields()
      this.loadingCurrencies = false
    },
    backToList() {
      this.setShowBlockName('itemList')
    },
    copyText () {
      let textToCopy = this.$refs.textToCopy.$el.querySelector('input')

      textToCopy.select()

      document.execCommand('copy')

      this.$store.dispatch('snackBar/run', 'Скопировано')
    },
    validate () {
      this.$refs.form.validate()
    },
    async loadTerms() {
      this.loadingTermList = true
      const response = await axios.get('setting/term-list')
      this.loadingTermList = false

      if (response.data.status === 'ok') {
        this.years = response.data.data
      }
      else {
        this.$store.dispatch('snackBar/run', 'Ошибка загрузки')
      }
    },

    async sendData() {

      if (this.loadingCreate) {
        return
      }

      this.validate()

      if (!this.formValid) {
        return
      }

      this.loadingCreate = true
      let data = {
        brief_case_id: this.getBriefCase.id,
        crypto_id: this.getCurrency.crypto_id,
        crypto_sum: this.sum,
        term: this.year
      }

      const response = await this.create(data)
      this.loadingCreate = false
      await this.$store.dispatch('snackBar/run', 'Сохранено')


      this.setSelectedDeposit(response.data.id)
      this.initFields()
      // let response = { data: {id: 37}}
      // this.reopenFields(response)
    },
    initFields() {
      let item = this.getSelectedDeposit

      if (!item) {
        item = new Deposit()
      }
      else {
        this.agreementTerms = true
        this.disabledFields = true
        this.showBtnNext = false
      }

      this.setSelectedItemById(item.currency.crypto_id)
      this.sum = item.crypto_sum
      this.disabledPaidBtn = item.user_paid_at !== null
      this.textPaidBtn = item.user_paid_at === null ? 'Я перевёл' : 'Запрос отправлен'
      this.year = item.term / 365
      this.status.name = item.status.name
      this.status.color = item.getStatusColor()
    },
    async sendAboutPaid() {
      this.loadingPaid = true
      let item = this.getSelectedDeposit
      const response = await axios.patch('/deposits/user_paid', {id: item.id})
      this.loadingPaid = false

      if (response.data.status === 'ok') {
        await this.$store.dispatch('deposits/loadAll', this.getBriefCase.id)

        this.initFields()
        this.dialog = true
      }
    },
    successDialog() {
      this.dialog = false

      this.setShowBlockName('itemList')
    }
  }
}
</script>

<style lang="scss" scoped>

.s-item-wrapper {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;

  .s-title {
    color: $g-color-dark-blue;
    font-size: 18px;
    font-weight: 600;
  }

  .s-row {
    margin-top: -30px;
  }
  .s-title-item {
    color: $g-color-black-main;
    font-size: 16px;
    font-weight: 400;
  }
  .s-desc-item {
    color: $g-color-gray;
    font-size: 14px;
    font-weight: 400;
  }
  .s-desktop-btn-back {
    color: #ffffff;
    background-color: $g-color-dark-blue;
  }
}
</style>
