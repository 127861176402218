<template>
  <div class="s-item-wrapper text--primary">
    <v-dialog
      v-model="dialogProlong"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Продление депозита</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  required
                  label="Срок депозита"
                  :items="termList"
                  :loading="loadingTerm"
                  v-model="termSelected"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogProlong = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loadingSendProlong"
            @click="sendProlongData()"
          >
            Продлить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div>
      <v-btn
        icon
        @click="backToList"
      >
        <v-icon left>mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div class="text-center">
      <div class="s-title">Депозит ID {{ item.id }}</div>
      <div class="s-date">от {{ item.created_at }}</div>
    </div>

    <v-row class="align-center">
      <v-col cols="6">
        <div class="d-flex align-center">
          <v-img
            class="mr-1"
            :src="item.currency.logo"
            max-width="20"
          ></v-img>
          <div class="s-crypto-name text-truncate">{{ item.currency.crypto_name }}</div>
        </div>
      </v-col>
      <v-col cols="6" class="text-right">
        <div>
          <span class="s-x">x</span>
          &nbsp;
          <span class="s-sum">{{ item.course_sum[getCurrency] }}</span>
        </div>

        <div
          class="text-right s-percent"
          :style="item.getCoursePercentColor(getCurrency)"
        >{{ item.getCoursePercentSum(getCurrency) }}%</div>
      </v-col>
    </v-row>

    <v-row class="align-center s-row">
      <v-col cols="6" class="s-col-title">Статус</v-col>
      <v-col cols="6" class="text-right">

        <chip-status-component
          :color="item.getStatusColor()"
          :text="item.status.name"
        ></chip-status-component>
      </v-col>
    </v-row>

    <v-row class="align-baseline s-row">
      <v-col cols="6" class="s-col-title">Количество</v-col>
      <v-col cols="6">
        <v-text-field
          disabled
          dense
          hide-details
          :value="item.crypto_sum"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="align-baseline s-row">
      <v-col cols="6" class="s-col-title">Срок депозита</v-col>
      <v-col cols="6">
        <v-text-field
          disabled
          dense
          hide-details
          :value="item.term"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="align-baseline s-row">
      <v-col cols="6" class="s-col-title">Курс входа</v-col>
      <v-col cols="6">
        <v-text-field
          disabled
          dense
          hide-details
          :value="getStartCourse(item)"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="align-baseline s-row">
      <v-col cols="6" class="s-col-title">в ({{ getCurrency | uppercase }}) по курсу</v-col>
      <v-col cols="6">
        <v-text-field
          disabled
          dense
          hide-details
          :value="getStartCourse(item)"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="align-center s-row">
      <v-col cols="6" class="s-col-title">Общая прибыль</v-col>
      <v-col cols="6" class="text-right">
        <div class="s-sum">{{ item.income_sum[getCurrency] }}</div>
        <div
          class="text-right s-percent"
          :style="item.getIncomePercentColor()"
        >{{ item.getIncomePercentSum() }}%</div>
      </v-col>
    </v-row>


    <div
      v-if="item.is_finished && item.status_id !== 3"
    >
      <v-divider></v-divider>

      <div class="text-center">
        <v-btn
          x-large
          block
          class="mt-3"
          color="#24B620"
          style="color: white"
          @click="prolong()"
        >
          Продлить депозит
        </v-btn>
        <v-btn
          x-large
          block
          class="mt-3"
          color="#A0A0A0"
          style="color: white"
        >
          Вывести средства
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import ChipStatusComponent from "../../ChipStatus";

export default {
  name: "BlockItem",
  components: { ChipStatusComponent },
  data: () => ({
    dialogProlong: false,
    termList: [],
    termSelected: null,
    loadingTerm: false,
    loadingSendProlong: false
  }),
  computed: {
    ...mapGetters('deposits', {getDeposit: 'getSelectedItem'}),
    ...mapGetters('choiceCurrencies', {getCurrency: 'getSelectedItem'}),

    item() {
      return this.getDeposit
    }
  },
  methods: {
    ...mapMutations('deposits', ['setShowBlockName']),

    backToList() {
      this.setShowBlockName('itemList')
    },
    getStartCourse(item) {
      let currency = this.getCurrency;
      currency = currency === 'btc' ? 'bitcoin' : currency

      let currencyStr = 'course_' + currency

      return item[currencyStr];
    },
    prolong() {
      this.dialogProlong = true
      this.loadTerms()
    },
    async loadTerms() {
      this.loadingTerm = true
      const response = await axios.get('setting/term-list')
      this.loadingTerm = false

      if (response.data.status === 'ok') {
        this.termList = response.data.data
      }
      else {
        this.$store.dispatch('snackBar/run', 'Ошибка загрузки')
      }
    },
    async sendProlongData() {
      if (this.loadingSendProlong) {
        return
      }

      let data = {
        term: this.termSelected,
        depositId: this.item.id
      }

      this.loadingSendProlong = true
      const response = await this.$store.dispatch('deposits/prolong', data)
      this.loadingSendProlong = false

      if (response.status !== 'ok') {
        return
      }

      this.dialogProlong = false
      this.$store.dispatch('snackBar/run', 'Депозит продлен')
      this.setShowBlockName('itemList')
    }
  }
}
</script>

<style lang="scss" scoped>

.s-item-wrapper {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;

  .s-title {
    color: $g-color-dark-blue;
    font-weight: 600;
    font-size: 18px;
  }
  .s-date {
    color: $g-color-dark-blue;
    font-weight: 400;
    font-size: 12px;
  }
  .s-crypto-name {
    color: $g-color-black-main;
    font-weight: 500;
    font-size: 16px;
  }
  .s-x {
    color: $g-color-gray;
    font-weight: 400;
    font-size: 14px;
  }
  .s-sum {
    color: $g-color-gray;
    font-weight: 400;
    font-size: 16px;
  }
  .s-percent {
    font-size: 14px;
    font-weight: 400;
  }
  .s-col-title {
    color: $g-color-black-main;
    font-weight: 400;
    font-size: 16px;
  }
  .s-row {
    margin-top: 0;
  }
}

</style>
