export default {
  namespaced: true,
  state: {
    status: '',
    token: localStorage.getItem('token') || null,
    user : {}
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    userName: state => state.user.first_name || null,
    getAvatar: state => state.user.avatar || null,
    getUser: state => state.user
  },
  mutations: {
    setToken(state, value) {
      state.token = 'Bearer ' + value
    },
    setUser(state, value) {
      state.user = value;
    },
    authRequest(state) {
      state.status = 'loading'
    },
    authSuccess(state, {token, user}) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    authError(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.user = ''
    }
  },
  actions: {
    async login({commit, dispatch}, data) {
      try {
        let response = await axios.post('login', data);
        const token = response.data.token
        const user = response.data.user

        if (response.data.status === 'ok') {
          dispatch('tokenInSave', {token, user})
          return true
        }

        return false
      }
      catch (error) {
        commit('authError')
        localStorage.removeItem('token')
      }
    },
    async register({commit, dispatch}, data) {

      try {
        let response = await axios.post('register', data)

        if (response.data.status === 'ok') {
          const token = response.data.token
          const user = response.data.user

          dispatch('tokenInSave', {token, user})

          return true
        }

        return false
      }
      catch (error) {
        commit('authError', error)
        localStorage.removeItem('token')
      }
    },
    async update({commit}, data) {
      const response = await axios.post('user/auth', data);
      console.log('response', response)

      if (!response || response.data.status !== 'ok') {
        console.log('response fail', response)
        return false
      }

      const user = response.data.data.user
      commit('setUser', user)

      return true
    },
    async loadAuthUser({commit}) {
      let response = await axios.get('user/auth')

      if (response.data.status === 'ok') {
        const user = response.data.data.user
        commit('setUser', user)
      }
    },
    async logout({dispatch}) {

      const response = await axios.get('logout');

      if (response.data.status === 'ok') {
        dispatch('logoutClient')
      }
    },
    logoutClient({commit}) {
      commit('logout')

      localStorage.removeItem('token')

      delete axios.defaults.headers.common['Authorization']
    },

    tokenInSave({commit, dispatch}, {token, user}) {
      commit('setToken', token)
      commit('setUser', user)

      dispatch('storageTokenSave')
      dispatch('headersTokenSave')
    },

    tokenInDelete({commit, dispatch}) {
      commit('setToken', null)
      commit('setUser', {})

      dispatch('storageTokenSave')
      dispatch('headersTokenSave')
    },

    storageTokenSave({state}) {
      localStorage.setItem('token', state.token)
    },
    headersTokenSave({state}) {
      axios.defaults.headers.common['Authorization'] = state.token
    }
  }
}
