<template>
  <div class="text-center mt-3">
    <div class="text-h5 mb-5">
      <span class="text-no-wrap">Добро пожаловать</span>
      &nbsp;
      <span class="text-no-wrap">в Bitrade Capital</span>
    </div>
    <div style="max-width: 350px; margin: 0 auto">

      <v-btn
          large
          block
          class="g-btn"
          :to="{name: 'register'}"
      >
        Регистрация
      </v-btn>
      <div class="mt-6">Ужа зарегистрированы?<br />Авторизация</div>

      <v-form
          ref="form"
          class="mt-3"
          v-model="formValid"
          @submit.prevent="login"
      >
        <div class="s-label">Номер телефона</div>
        <div class="s-phone-btn-link">
            <v-text-field
                solo
                background-color="white"
                label="+7"
                v-mask="'+7 (###) ###-##-##'"
                :rules="phoneRules"
                v-model="phone"
            >
          </v-text-field>
          <v-btn
            text
            small
            v-if="visibilitySmsCodeBtn"
            :loading="loadingSmsCodeBtn"
            @click.prevent="getSmsCode"
          >
            Подтвердить
          </v-btn>
        </div>

        <div class="s-label">SMS-код</div>
        <v-text-field
            solo
            background-color="white"
            label="Введите код из SMS"
            :rules="smsCodeRules"
            v-model="smsCode"
        ></v-text-field>
        <v-btn
            large
            class="g-btn"
            :loading="loadingLogin"
            type="submit"
        >
          Войти
        </v-btn>
      </v-form>

    </div>

  </div>
</template>


<script>

export default {
  name: 'Login',
  data: () => ({
    loadingLogin: false,
    formValid: true,
    phone: '',
    smsCode: '',
    visibilitySmsCodeBtn: true,
    loadingSmsCodeBtn: false,
    smsKey: 'qwerty',
    phoneRules: [
      v => !!v || 'Обязательное поле',
      v => (v && v.length === 18) || 'Не корректные данные',
    ],
    smsCodeRules: [
      v => !!v || 'Обязательное поле',
    ],
  }),
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    async login() {
      if (this.loadingLogin) {
        return
      }

      this.validate()

      if (!this.formValid) {
        return
      }

      let data = {
        phone: this.removeSymbolsFromNumber(this.phone),
        smsCode: this.smsCode,
        smsKey: this.smsKey
      }

      this.loadingLogin = true
      const res = await this.$store.dispatch('auth/login', data)
      this.loadingLogin = false

      if (res) {
        await this.$router.push({name: 'account.deposits'})
      }
    },
    async getSmsCode() {
      let phone = this.removeSymbolsFromNumber(this.phone)

      if (!phone || phone.length !== 11) {
        await this.$store.dispatch('snackBar/run', 'Укажите номер телефона')
        return
      }

      this.loadingSmsCodeBtn = true
      const response = await axios.post('auth/sms-code', {phone: this.phone});
      this.loadingSmsCodeBtn = false

      if (response.data.status !== 'ok') {
        return
      }

      await this.$store.dispatch('snackBar/run', 'На указанный номер телефона отправлен код подтверждения')

      this.smsKey = response.data.data.key
      this.visibilitySmsCodeBtn = false

      setTimeout(() => {
        this.visibilitySmsCodeBtn = true
      }, 30000);
    },
    removeSymbolsFromNumber(val) {
      if (!val) {
        return val
      }

      val = val.toString().replace(/[^\d]/g, '')

      return val
    },
  }
}
</script>

<style lang="scss" scoped>

.s-label {
  font-size: 14px;
  color: $g-color-gold;
  text-align: left;
}
.s-phone-btn-link {
  position: relative;

  button {
    position: absolute;
    right: 5px;
    top: 10px;
    color: $g-color-dark-blue;
  }
}
</style>
