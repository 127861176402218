import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import VueTheMask from 'vue-the-mask'

import './axios'
import './filters'

Vue.config.productionTip = false
// Vue.use(VueMask);
Vue.use(VueTheMask)

const VueInstance = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
