<template>
  <div class="s-block-empty-brief-cases d-flex justify-center align-center">
    <dialog-new-brief-case-component
        v-model="dialog"
    ></dialog-new-brief-case-component>

    <div class="text-center">
      <v-icon class="s-icon-case">mdi-briefcase</v-icon>
      <div class="s-text">Пока у вас нет инвестиций.</div>
      <div class="s-text-create">Создать?</div>
      <v-btn
          fab
          small
          color="#D1B986"
          class="mt-2"
          @click="dialog = true"
      >
        <v-icon large>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import DialogNewBriefCaseComponent from "./DialogCreate";

export default {
  name: "BriefCaseEmpty",
  components: { DialogNewBriefCaseComponent },
  data: () => ({
    dialog: false
  })
}
</script>

<style lang="scss" scoped>

.s-block-empty-brief-cases {
  height: calc(100vh - 360px);

  @media screen and (max-width: 960px) {
    height: calc(100vh - 100px);
  }
}

.s-icon-case {
  font-size: 128px;
  color: #6A6A6A;
}
.s-text {
  font-weight: 400;
  font-size: 18px;
}
.s-text-create {
  font-weight: 600;
  font-size: 18px;
  color: $g-color-gold;
}
</style>
