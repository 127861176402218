<template>
  <div class="text-center mt-3">
    <div class="text-h5 mb-5">Создать аккаунт</div>
    <v-form
      ref="form"
      style="max-width: 350px; margin: 0 auto"
      v-model="formValid"
      @submit.prevent="register"
    >
      <div class="s-label">Ваше имя</div>
      <v-text-field
          solo
          background-color="white"
          label="Введите ваше имя"
          :rules="requiredFieldRules"
          v-model="name"
      ></v-text-field>

      <div class="s-label">Ваш E-mail</div>
      <v-text-field
          solo
          background-color="white"
          label="Введите ваш E-mail"
          :rules="emailRules"
          v-model="email"
      ></v-text-field>

      <div class="s-label">Ваш номер телефона</div>
      <div class="s-phone-btn-link">
        <v-text-field
          solo
          background-color="white"
          label="+7"
          v-mask="'+7 (###) ###-##-##'"
          :rules="phoneRules"
          v-model="phone"
        >
        </v-text-field>
        <v-btn
          text
          small
          v-if="visibilitySmsCodeBtn"
          :loading="loadingSmsCodeBtn"
          @click.prevent="getSmsCode"
        >
          Подтвердить
        </v-btn>
      </div>

      <div class="s-label">SMS-код</div>
      <v-text-field
          solo
          background-color="white"
          label="Введите код из SMS"
          v-mask="'##########'"
          :rules="smsCodeRules"
          v-model="smsCode"
      ></v-text-field>

      <v-btn
          large
          class="g-btn"
          :loading="loadingRegister"
          type="submit"
      >
        Создать
      </v-btn>
    </v-form>
  </div>
</template>

<script>

export default {
  name: 'Register',
  data: () => ({
    loadingRegister: false,
    formValid: false,
    name: null,
    email: null,
    phone: null,
    smsCode: null,
    visibilitySmsCodeBtn: true,
    loadingSmsCodeBtn: false,
    smsKey: 'qwerty',
    requiredFieldRules: [
      v => !!v || 'Обязательное поле',
    ],
    emailRules: [
      v => !!v || 'Обязательное поле',
      v => /.+@.+\..+/.test(v) || 'Не корректные данные',
    ],
    phoneRules: [
      v => !!v || 'Обязательное поле',
      v => (v && v.length === 18) || 'Не корректные данные',
    ],
    smsCodeRules: [
      v => !!v || 'Обязательное поле',
      v => /\d+/.test(v) || 'Не корректные данные',
    ],
  }),
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    async register() {
      if (this.loadingRegister) {
        return
      }

      let data = {
        name: this.name,
        email: this.email,
        phone: this.removeSymbolsFromNumber(this.phone),
        smsCode: this.smsCode,
        smsKey: this.smsKey
      }

      this.validate()

      if (!this.formValid) {
        return
      }

      this.loadingRegister = true
      const res = await this.$store.dispatch('auth/register', data)
      this.loadingRegister = false

      if (res) {
        await this.$router.push({name: 'account.deposits'})
      }
    },
    async getSmsCode() {
      let phone = this.removeSymbolsFromNumber(this.phone)

      if (!phone || phone.length !== 11) {
        await this.$store.dispatch('snackBar/run', 'Укажите номер телефона')
        return
      }

      this.loadingSmsCodeBtn = true
      const response = await axios.post('auth/sms-code', {phone: this.phone});
      this.loadingSmsCodeBtn = false

      if (response.data.status !== 'ok') {
        return
      }

      await this.$store.dispatch('snackBar/run', 'На указанный номер телефона отправлен код подтверждения')

      this.smsKey = response.data.data.key
      this.visibilitySmsCodeBtn = false

      setTimeout(() => {
        this.visibilitySmsCodeBtn = true
      }, 30000);
    },
    removeSymbolsFromNumber(val) {
      if (!val) {
        return val
      }

      val = val.toString().replace(/[^\d]/g, '')

      return val
    },
  }
}
</script>

<style lang="scss" scoped>


  .s-label {
    font-size: 14px;
    color: $g-color-gold;
    text-align: left;
  }
  .s-phone-btn-link {
    position: relative;

    button {
      position: absolute;
      right: 5px;
      top: 10px;
      color: $g-color-dark-blue;
    }
  }
</style>
