<template>
<div class="s-main">


  <SideBarComponent />

  <v-main class="pa-0">
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-main>

  <FooterComponent />

</div>
</template>

<script>

import SideBarComponent from "../components/SideBar";
import FooterComponent from "../components/Footer";

export default {
  components: {
    SideBarComponent,
    FooterComponent
  },
  data: () => ({
  }),
}
</script>

<style lang="scss" scoped>
  .s-main {
    background-image:
      linear-gradient(0deg, rgba(0, 18, 66, 0.85), rgba(0, 18, 66, 0.85)),
      url("/images/background_main.jpg");
    background-size: cover;
    background-color: #090842;
    background-position: center center;
    color: #ffffff;
    height: 100vh;
  }

  .s-title {
    color: #ffffff;
  }

  .s-router-link {
    text-decoration: none;
    color: inherit;
  }
</style>
