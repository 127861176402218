<template>
  <div
      class="s-deposit-item"
  >
    <div class="s-top-line">
      <div class="s-date-line">ID {{ item.id }} от {{ item.created_at}}</div>

      <chip-status-component
        color="#00AE26"
        text="В работе"
      ></chip-status-component>
    </div>
    <v-divider></v-divider>
    <div class="s-body">
      <v-row>
        <v-col cols="4">
          <div class="s-title">
            <v-img
                class="mr-1"
                :src="item.in_currency.logo"
                max-width="20"
            ></v-img>
            <div class="text-truncate s-crypto-name">{{ item.in_currency.crypto_name }}</div>
          </div>
          <div class="s-title s-crypto-sum mt-1">
            <div class="mr-2 text-truncate">{{ item.sum }}</div>
          </div>
        </v-col>

        <v-col cols="4" class="d-flex justify-center align-center">
          <v-icon class="s-icon">mdi-sync-circle</v-icon>
        </v-col>
        <v-col cols="4">
          <div class="s-title">
            <v-img
              class="mr-1"
              :src="item.out_currency.logo"
              max-width="20"
            ></v-img>
            <div class="text-truncate s-crypto-name">{{ item.out_currency.crypto_name }}</div>
          </div>
<!--          <div class="s-title s-crypto-sum mt-1">-->
<!--            <div class="mr-2 text-truncate">{{ item.sum }}</div>-->
<!--          </div>-->
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ChipStatusComponent from "../../ChipStatus";

export default {
  name: "ListItemMobile",
  components: { ChipStatusComponent },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
  }),
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.s-icon {
  font-size: 24px;
  color: #6A6A6A;
}

  .s-deposit-item {
    padding: 5px 10px;
    background-color: #ffffff;
    border-radius: 4px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .s-top-line {
      color: #323232;
      display: flex;
      justify-content: space-between;

      .s-date-line {
        color: $g-color-black-main;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .s-body {
      margin-top: 5px;
      color: #323232;

      .s-title {
        display: flex;
        align-items: center;
      }

      .s-crypto-name {
        color: $g-color-black-main;
        font-size: 16px;
        font-weight: 500;
      }
      .s-crypto-sum {
        color: $g-color-gray;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
</style>
