<template>
  <div class="s-block-empty-deposits">

    <div class="text-center s-block-text">
      <v-icon class="s-icon-case">mdi-briefcase</v-icon>
      <div>Необходимо создать заявку на пополение вашего счета.</div>
      <div>Создать?</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DepositEmpty",
  data: () => ({
    dialog: false
  }),
}
</script>

<style lang="scss" scoped>

.s-block-empty-deposits {
  margin-top: 150px;
}

.s-icon-case {
  font-size: 128px;
  color: #6A6A6A;
}
.s-block-text {
  margin-top: -70px;

  div {
    font-size: 18px;

    &:first-child {
      font-weight: 400;
    }
    &:last-child {
      color: $g-color-gold;
      font-weight: 600;
    }
  }
}
</style>
