<template>
  <div class="s-main">

    <AppBarComponent
      v-if="$vuetify.breakpoint.mdAndUp"
    />
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="s-block-header d-none d-md-flex justify-center align-end"
    >
      <div class="s-header__title">{{ $route.meta.title }}</div>
    </div>

    <SideBarComponent />

    <v-app-bar
        flat
        class="mt"
        color="#D1B986"
        :app="$vuetify.breakpoint.smAndDown"
    >
      <v-container
          :fluid="$vuetify.breakpoint.smAndDown"
          class="d-flex justify-space-between pa-0"
      >
        <v-progress-circular
          v-if="loadingUser"
          indeterminate
        ></v-progress-circular>
        <div
          v-else
          class="d-flex align-center"
        >
          <v-avatar size="42">
            <v-img
              v-if="getAvatar"
              :src="getAvatar"
            ></v-img>
            <v-img
              v-else
              src="/images/avatar.png"
            ></v-img>
          </v-avatar>
          <div
            class="text-subtitle-1 ml-2 g-text--dark-blue"
          >
            Добро пожаловать, {{ userName }}!
          </div>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-show="$vuetify.breakpoint.smAndDown"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon large class="g-text--dark-blue">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list
            class="s-list"
          >
            <v-list-item
                link
                v-for="(item, index) in items" :key="index"
                :to="{name: item.name}"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>

            <v-divider class="s-divider"></v-divider>

            <v-list-item @click="logout">
              <v-list-item-title>Выйти</v-list-item-title>
              <v-list-item-icon>
                <v-icon class="g-text--dark-blue">mdi-logout-variant</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-container>
    </v-app-bar>

    <v-main class="pt-md-0">
      <v-container fluid>
        <snack-bar-component></snack-bar-component>
        <router-view></router-view>
      </v-container>
    </v-main>

    <FooterComponent />

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SnackBarComponent from "../components/SnackBar";
import FooterComponent from "../components/Footer";
import HeaderTopLineComponent from "../components/HeaderTopLine";
import AppBarComponent from "../components/AppBar";
import SideBarComponent from "../components/SideBar";

export default {
  components: {
    SnackBarComponent,
    FooterComponent,
    HeaderTopLineComponent,
    AppBarComponent,
    SideBarComponent
  },
  data: () => ({
    drawer: false,
    loadingUser: false,
    items: [
      { title: 'Мой профиль', name: 'account.profile' },
      { title: 'Депозиты', name: 'account.deposits' },
      { title: 'Обменник', name: 'account.exchanger' },
    ],
  }),
  mounted() {
    this.checkAuthUser()
  },
  computed: {
    ...mapGetters('auth', ['userName', 'getAvatar'])
  },
  methods: {
    async logout() {
      await this.$router.push('/')
      await this.$store.dispatch('auth/logout')
    },
    async checkAuthUser() {

      if (!this.userName) {
        this.loadingUser = true
        await this.$store.dispatch('auth/loadAuthUser')
        this.loadingUser = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.s-main {
  background-color: $g-color-dark-blue;
  color: #ffffff;
  min-height: 100vh;
}
.s-block-header {
  background-image:
    linear-gradient(0deg, rgba(0, 18, 66, 0.85), rgba(0, 18, 66, 0.85)),
    url("/images/background_main.jpg");
  background-size: cover;
  background-color: #090842;
  background-position: top center;
  margin: 0 -12px;
  height: 160px;

  .s-header__title {
    font-weight: 400;
    font-size: 64px;
  }
}
.s-list {
  background-color: $g-color-gold;
  font-weight: 600;

  div {
    color: $g-color-dark-blue !important;
  }
}
.s-divider {
  margin: 10px 0 10px 5px;
  width: 100px;
  border-color: $g-color-dark-blue;
}
</style>


