import BriefCase from "../../entities/BriefCase";

export default {
    namespaced: true,
    state: {
        items: [],
        selectedIdx: 0
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload
        },
        setSelectedIdx(state, payload) {
            state.selectedIdx = payload
        },
        resetItems(state) {
            state.items = []
        }
    },
    getters: {
        getItems(state) {
            return state.items
        },
        getSelectedIdx(state) {
            return state.selectedIdx
        },
        getSelectedItem(state) {
            if (!state.items[state.selectedIdx]) {
                return null
            }

            return state.items[state.selectedIdx]
        }
    },
    actions: {
        async reloadAll({commit, dispatch}) {
            commit('resetItems')
            dispatch('loadAll')
        },
        async loadAll ({commit}) {

            const items = []
            const url = '/brief_cases'

            try {
                const response = await axios.get(url);
                const collection = response.data.data;

                Object.keys(collection).forEach(key => {
                    items.push(BriefCase.fill(collection[key]))
                })

                commit('resetItems')
                commit('setItems', items)
            } catch (error) {
                // commit('setError', error.message, {root: true})
                throw error
            }
        },
        // async getFromServerByUuid ({ commit }, {uuid}) {
        //
        //     const url = `/api/screen/button/${uuid}`
        //
        //     try {
        //         const response = await axios.get(url);
        //         let data = response.data;
        //         let button = new Button(data);
        //
        //         commit('setButtonCurrent', button)
        //     } catch (error) {
        //         commit('setError', error.message, {root: true})
        //         throw error
        //     }
        // },
        async create({commit, getters, dispatch}, data) {
            const url = '/brief_cases'

            try {
                const response = await axios.post(url, data);
                let responseData = response.data;

                if (responseData.status === 'ok') {
                    dispatch('loadAll')
                }
            } catch (error) {
                // commit('setError', error.message, {root: true})
                throw error
            }
        },
        // async saveCurrent({commit, getters, dispatch}) {
        //     const current = getters.buttonCurrent;
        //     const url = `/api/screen/button/${current.uuid}`
        //     const data = {
        //         text        : current.text,
        //         options     : current.options,
        //         uuid        : current.uuid,
        //         inputOptions: current.inputOptions
        //     }
        //
        //     try {
        //         const response = await axios.patch(url, data);
        //         let responseData = response.data;
        //
        //         if (responseData.status === 'ok') {
        //             let screenUuid = getters.screenUuid
        //
        //             dispatch('getFromServerByScreenUuid', {id: screenUuid})
        //             dispatch('GM', 'Кнопка сохранена', {root: true})
        //         }
        //     } catch (error) {
        //         commit('setError', error.message, {root: true})
        //         throw error
        //     }
        // },
        async removeByIdx({commit, getters, dispatch}, idx) {
            const item = getters.getItems[idx] || null;

            if (!item) {
                return
            }

            const url = `brief_cases/${item.id}`

            try {
                const response = await axios.delete(url);

                if (response.data.status === 'ok') {
                    dispatch('loadAll')
                }
            } catch (error) {
                throw error
            }
        },
    }
}
