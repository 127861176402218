<template>
  <v-snackbar
      top
      v-model="snackBarIsShow"
      :timeout="5000"
  >
    <template v-slot:default>
      <div v-html="shackBarText"></div>
    </template>
    <template v-slot:action="{ attrs }">
      <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="closeSnackBar()"
      >Ok</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'SnackBar',
    props: ['value'],
    data: () => ({

    }),
    computed: {
      snackBarIsShow: {
        get () {
          return this.$store.getters['snackBar/isShow'];
        },
        set (value) {
          this.$store.commit('snackBar/isShow', value);
        }
      },
      shackBarText() {
        return this.$store.getters['snackBar/getText'];
      }
    },
    methods: {
      closeSnackBar() {
        this.$store.commit('snackBar/hide');
      },
    }
  }
</script>
