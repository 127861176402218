<template>
  <div>
    <v-navigation-drawer
      app
      right
      temporary
      width="100%"
      color="#D9D9D9"
      v-model="showDrawer"
      v-if="$vuetify.breakpoint.smAndDown"
    >


      <div class="s-block-btn-close">
        <v-icon
          large
          color="#D1B986"
          @click="closeMenu"
        >mdi-close</v-icon>
      </div>
      <v-list
        nav
        dense
      >
        <v-list-item
          v-for="(page, idx) in pages" :key="idx"
          :to="{name: page.name}"
          :exact="page.exact"
          @click="closeMenu"
        >
          <div class="s-list-item">{{ page.title }}</div>
        </v-list-item>

        <v-divider class="s-divider"></v-divider>

        <v-list-item :to="{name: 'login'}">
          <div class="s-list-item">Войти/Регистрация</div>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

  <v-navigation-drawer
      app
      right
      temporary
      overlay-color="#001242"
      overlay-opacity="0.9"
      width="40%"
      class="s-sidebar"
      color="transparent"
      v-model="showDrawer"
      v-if="$vuetify.breakpoint.mdAndUp"
  >

    <div class="s-block-btn-close">
      <v-icon
          x-large
          color="#ffffff"
          @click="closeMenu"
      >mdi-close</v-icon>
    </div>
    <v-list
        nav
        dense
    >
      <v-list-item
          v-for="(page, idx) in pages" :key="idx"
          :to="{name: page.name}"
          :exact="page.exact"
          active-class="s-list-md-item-active"
          @click="closeMenu"
      >
        <div class="s-list-md-item">{{ page.title }}</div>
      </v-list-item>

      <v-divider class="s-divider"></v-divider>

      <v-list-item
        v-if="!isLoggedIn"
        active-class="s-list-md-item-active"
        :to="{name: 'login'}"
      >
        <div class="s-list-md-item">Войти/Регистрация</div>
      </v-list-item>

      <div
        v-if="isLoggedIn">

        <v-list-item
          v-for="page in accountPages" :key="page.name"
          :to="{name: page.name}"
          :exact="page.exact"
          active-class="s-list-md-item-active"
          @click="closeMenu"
        >
          <div class="s-list-md-item">{{ page.title }}</div>
        </v-list-item>

        <v-divider class="s-divider"></v-divider>

        <v-list-item
          @click="logout"
        >
          <div class="s-list-md-item">Выйти</div>
        </v-list-item>
      </div>

    </v-list>
  </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'SideBar',
  data: () => ({
    pages: [
      // {title: 'Главная', name: 'home', exact: true},
      {title: 'О фонде', name: 'about_fund'},
      {title: 'Условия инвестирования', name: 'investment'},
      {title: 'Безопасность', name: 'security'},
      {title: 'Контакты', name: 'contacts'},
    ],
    accountPages: [
      { title: 'Мой профиль', name: 'account.profile' },
      { title: 'Депозиты', name: 'account.deposits' },
      { title: 'Обменник', name: 'account.exchanger' },
    ],
  }),
  computed : {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
    showDrawer: {
      get() {
        return this.$store.getters.getVisibilitySideBar
      },
      set(val) {
        this.$store.commit('setShowSideBar', val)
      }
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      await this.$router.push('/')
    },
    closeMenu() {
      this.$store.commit('hideSideBar')
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "~vuetify/src/styles/main";

  .s-block-btn-close {
    text-align: right;
    .v-icon {
      margin-right: 15px;
      margin-top: 15px;
    }
  }
  .s-sidebar {
    box-shadow: none !important;
    .v-navigation-drawer__border {
      width: 0 !important;
    }
  }

  .s-list-md-item-active {
    .s-list-md-item {
      color: $g-color-gold !important;
    }
  }

  .s-list-md-item {
    color: #ffffff;
    font-weight: 500;
    font-size: 24px;
  }

  .s-list-item {
    @extend .text-h6;
    color: #001242;
  }
  .s-divider {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    border-color: #D1B986 !important;
    width: 148px;
  }
</style>

<style lang="scss">
.s-sidebar .v-navigation-drawer__border {
    width: 0 !important;
}
</style>
