
class BaseEntity {
    constructor()
    {

    }

    static fill(data)
    {
        let entity = new this()
        entity._creator(data)

        return entity;
    }

    _creator(data = {})
    {
        data = data || {}

        for (const i in data) {
            // if (!data.hasOwnProperty(i)) {
            //     continue;
            // }

            this[i] = data[i] || null
        }

    }

}

export default BaseEntity;
