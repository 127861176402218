import FundCurrency from "../../entities/FundCurrency";

export default {
    namespaced: true,
    state: {
        items: [],
        selectedItem: null
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload
        },
        resetItems(state) {
            state.items = []
        },
        setSelectedItem(state, payload) {
            state.selectedItem = payload
        }
    },
    getters: {
        getItems(state) {
            return state.items
        },
        getSelectedItem(state) {
            return state.selectedItem
        }
    },
    actions: {
        async loadAll ({commit}) {

            const items = []
            const url = '/crypto/list'

            try {
                const response = await axios.get(url);
                const collection = response.data.data;

                Object.keys(collection).forEach(key => {
                    items.push(FundCurrency.fill(collection[key]))
                })

                commit('resetItems')
                commit('setItems', items)
            } catch (error) {
                // commit('setError', error.message, {root: true})
                throw error
            }
        },
        setSelectedItemById({commit, state}, id) {
            const item = state.items.find(item => {
                if (id === item.crypto_id) {
                    return item
                }
            })

            commit('setSelectedItem', item);
        }
    }
}
