<template>
<div class="s-main">

  <AppBarComponent />

  <SideBarComponent />

  <v-main>
    <v-container fluid>

      <snack-bar-component></snack-bar-component>

      <HeaderTopLineComponent />

      <div :class="{'s-router-block': $vuetify.breakpoint.smAndUp}">
        <router-view></router-view>
      </div>

    </v-container>
  </v-main>

  <FooterComponent />
</div>
</template>

<script>
import SideBarComponent from "../components/SideBar";
import SnackBarComponent from "../components/SnackBar";
import FooterComponent from "../components/Footer";
import AppBarComponent from "../components/AppBar";
import HeaderTopLineComponent from "../components/HeaderTopLine";

export default {
  components: {
    SideBarComponent,
    SnackBarComponent,
    FooterComponent,
    AppBarComponent,
    HeaderTopLineComponent
  },
  data: () => ({

  }),
  watch: {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
  .s-main {
    background-color: #090842;
    color: #ffffff;
    min-height: 100vh;
  }

  .s-router-block {
    padding: 70px;
  }

  .s-router-link {
    text-decoration: none;
    color: inherit;
  }
</style>
