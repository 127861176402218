
export default {
    namespaced: true,
    state: {
        selectedItem: 'usd',
        items: ['btc', 'usd', 'rub']
    },
    mutations: {
        setSelectedItem(state, payload) {
            state.selectedItem = payload
        }
    },
    getters: {
        getItems(state) {
            return state.items
        },
        getSelectedItem(state) {
            return state.selectedItem
        }
    },
    actions: {

    }
}
