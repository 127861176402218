<template>
  <v-app-bar
    app
    :flat="getAppBarFlat()"
    :color="getAppBarColor()"
  >
    <v-container
      :fluid="$vuetify.breakpoint.smAndDown"
      class="d-flex justify-space-between pa-0"
    >
      <router-link
        :to="{name: 'home'}"
        class="d-flex align-center"
      >
        <div class="d-flex justify-center d-md-none">
          <v-img
            max-width="29"
            max-height="24"
            src="/images/logo.png"
            class="mr-1"
          ></v-img>
          <v-img
            max-width="66"
            max-height="23"
            src="/images/main_title.png"
          ></v-img>
        </div>
        <div class="justify-center align-center d-none d-md-flex">
          <v-img
            max-width="102"
            max-height="14"
            src="/images/bitrade_title_logo.png"
            class="mr-4"
          ></v-img>
          <v-img
            max-width="36"
            max-height="29"
            src="/images/logo.png"
            class="mr-4"
          ></v-img>
          <v-img
            max-width="98"
            max-height="14"
            src="/images/capital_title_logo.png"
          ></v-img>
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        :x-large="$vuetify.breakpoint.mdAndUp"
        class="white--text"
        @click.stop="toggleSideBar"
      ></v-app-bar-nav-icon>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: "AppBar",
  data: () => ({
    offsetTop: 0
  }),
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  computed: {
    ...mapGetters(['getVisibilitySideBar']),
  },
  methods: {
    ...mapMutations(['toggleSideBar']),

    getAppBarColor() {
      return this.offsetTop <= 30 ? 'transparent' : '#090842'
    },
    getAppBarFlat() {
      return this.offsetTop <= 30;
    },
    onScroll(e) {
      this.offsetTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
}
</script>

<style scoped>

</style>
