<template>
  <v-app>
    <component :is="layout"></component>
  </v-app>
</template>

<script>

import MainPageLayout from "./layouts/MainPageLayout";
import PagesLayout from './layouts/PagesLayout'
import AccountLayout from './layouts/AccountLayout'

export default {
  name: 'App',
  components: { MainPageLayout, PagesLayout, AccountLayout },
  data: () => ({
    //
  }),

  computed: {
    layout() {
      return (this.$route.meta.layout || 'pages') + '-layout'
    }
  },
  watch: {
    layout() {
      this.$store.commit('hideSideBar')
    }
  },
  methods: {

  }
};
</script>
