<template>
  <div style="width: 100%">
    <dialog-new-brief-case-component
        v-model="dialog"
    ></dialog-new-brief-case-component>

    <v-menu
        v-if="getItems.length"
        offset-y
        max-width="100%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            text
            small
            color="white"
            class="s-btn-menu s-brief-case-menu-btn"
            v-bind="attrs"
            v-on="on"
        >
          <div class="text-truncate s-font-size s-case-title">{{ showCaseName }}</div>
          <v-icon
              large
              right
          >
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <v-list
        v-if="getItems"
      >
        <v-list-item
            link
            v-for="(item, idx) in getItems" :key="item.id"
        >
          <v-list-item-title
            class="s-font-size"
            @click="setSelectedIdx(idx)"
          >{{ item.name }}</v-list-item-title>

          <v-list-item-action>
            <v-btn
              icon
              :loading="loadingRemoveBtn(idx)"
              @click.stop="deleteBriefCase(idx)"
            >
              <v-icon color="red lighten-2">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider class="s-divider"></v-divider>

        <v-list-item @click="dialog = true">
          <v-list-item-title class="s-font-size">Создать новый</v-list-item-title>
          <v-list-item-icon>
            <v-icon size="28" class="g-text--dark-blue">mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import DialogNewBriefCaseComponent from "./DialogCreate";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "BriefCaseChoice",
  components: { DialogNewBriefCaseComponent },
  data: () => ({
    dialog: false,
    removeBtnIdx: null,
  }),
  async mounted() {
    await this.loadAll()
  },
  computed: {
    ...mapGetters('briefCases', ['getItems', 'getSelectedIdx']),

    showCaseName() {
      if (!this.getItems.length) {
        return null
      }

      return this.getItems[this.getSelectedIdx].name
    }
  },
  methods: {
    ...mapActions('briefCases', ['loadAll', 'removeByIdx']),
    ...mapMutations('briefCases', ['setSelectedIdx']),

    loadingRemoveBtn(idx) {
      return idx === this.removeBtnIdx;
    },
    async deleteBriefCase(idx) {

      if (confirm('Удалить?')) {
        this.removeBtnIdx = idx

        await this.removeByIdx(idx)
        this.setSelectedIdx(0)

        this.removeBtnIdx = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.s-font-size {
  font-weight: 600;
  font-size: 18px;
}
.s-divider {
  color: $g-color-gold;
  margin: 10px 0 10px 5px;
  width: 100px;
}
.s-case-title {
  margin-left: -10px;
}
.s-btn-menu {
  max-width: 100%;
  justify-content: left;

}
</style>

<style lang="scss">
.s-brief-case-menu-btn .v-btn__content {
  width: 100%;
}
</style>
