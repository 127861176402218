<template>

  <v-footer
    app
    absolute
    padless
    style="height: 100px"
    class="d-none d-md-flex "
  >
    <v-row class="s-footer fill-height">
      <v-col cols="12">
        <span>Политика конфеденциальности</span>
        <span>Условия использования</span>
      </v-col>
      <v-col cols="12">
        <span>Copyright &copy; 2021 Bitrade Capital. Все права защищены.</span>
      </v-col>
    </v-row>

  </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
.s-footer {
  background-color: #11214A;

  div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: #A1A1A1;
      font-size: 14px;
    }
  }

  & div:first-child span {
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 40px;
  }
  & div:last-child span {
      font-weight: 400;
  }
}
</style>
