<template>
  <v-btn-toggle
      v-model="toggleCurrency"
  >
    <v-btn
        v-for="currency in currencies" :key="currency"

        small
        class="s-btn"
        color="#D1B986"
        :value="currency"
    >
      {{ currency | uppercase }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "CurrencyButtons",
  data: () => ({

  }),
  computed: {
    ...mapGetters('choiceCurrencies', ['getItems', 'getSelectedItem']),

    currencies() {
      return this.getItems
    },
    toggleCurrency: {
      get() {
        return this.getSelectedItem
      },
      set(val) {
        this.setSelectedItem(val)
      }
    }
  },
  methods: {
    ...mapMutations('choiceCurrencies', ['setSelectedItem'])
  }
}
</script>

<style lang="scss" scoped>
.s-btn {
  font-size: 14px;
  font-weight: 400;
  color: $g-color-dark-blue;

  @media screen and (max-width: 375px) {
    padding: 0 !important;
  }
}


</style>
