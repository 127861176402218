<template>
<div>

  <v-container
    :fluid="$vuetify.breakpoint.smAndDown"
    class="d-flex justify-space-between pa-0"
  >
    <v-row>
      <v-col cols="6" class="d-flex justify-start">
        <brief-case-choice-component></brief-case-choice-component>
      </v-col>

      <v-col cols="6" class="s-currency-btn-wrapper d-flex justify-end">
        <currency-buttons-component></currency-buttons-component>
      </v-col>

    </v-row>
  </v-container>


  <brief-case-empty-component v-if="showBlock === 'empty'"></brief-case-empty-component>
  <deposit-block-component v-else-if="showBlock === 'deposit'"></deposit-block-component>
  <v-progress-circular
    v-else-if="!showBlock"
    indeterminate
    color="amber"
  ></v-progress-circular>


</div>
</template>

<script>
import CurrencyButtonsComponent from "../../components/account/CurrencyButtons";
import BriefCaseChoiceComponent from "../../components/account/brief_cases/MenuList";
import BriefCaseEmptyComponent from "../../components/account/brief_cases/PageEmpty";
import DepositBlockComponent from "../../components/account/deposits/MainBlock";

import {mapGetters} from 'vuex'

export default {
  name: "Deposits",
  components: {
    CurrencyButtonsComponent,
    BriefCaseChoiceComponent,
    BriefCaseEmptyComponent,
    DepositBlockComponent
  },
  data: () => ({
    showBlock: null
  }),
  mounted() {

  },
  computed: {
    ...mapGetters('briefCases', ['getItems']),
  },
  watch: {
    getItems(val) {
      this.showBlock = val.length ? 'deposit' : 'empty'
    },
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.s-currency-btn-wrapper {
  @media screen and (max-width: 375px) {
    padding-left: 0;
    padding-right: 10px;
  }
}
</style>
