<template>
    <v-container
        v-ripple="{ class: 'blue--text' }"
        class="s-deposit-item"
        @click="setIdx(item.id)"
    >
      <v-row class="s-top-line">
        <v-col cols="3" class="s-date-line">ID {{ item.id }} от {{ item.created_at}}</v-col>
        <v-col>Количество</v-col>
        <v-col class="text-right">Цена</v-col>
        <v-col class="text-right">Сумма</v-col>
        <v-col class="text-right">Статус</v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="s-body">
        <v-col cols="3" class="d-flex align-center">
          <v-img
              class="mr-1"
              :src="item.currency.logo"
              max-width="20"
          ></v-img>
          <div class="text-truncate s-crypto-name">{{ item.currency.crypto_name }}</div>
        </v-col>
        <v-col class="s-crypto-sum d-flex align-center">
          <div class="mr-2 text-truncate">{{ item.crypto_sum }}</div>
          <div>{{ item.currency.crypto_short_name }}</div>
        </v-col>
        <v-col>
          <div class="text-right text-truncate">
            <span class="s-x">x</span>
            &nbsp;
            <span class="s-top-sum">{{ item.course_sum[getSelectedItem] }}</span>
          </div>
          <div
            class="text-right s-percent mt-1"
            :style="item.getCoursePercentColor(getSelectedItem)"
          >{{ item.getCoursePercentSum(getSelectedItem) }}%</div>
        </v-col>
        <v-col>
          <div class="text-right s-top-sum">{{ item.income_sum[getSelectedItem] }}</div>
          <div
            class="text-right s-percent mt-1"
            :style="item.getIncomePercentColor()"
          >{{ item.getIncomePercentSum() }}%</div>
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <chip-status-component
            :color="item.getStatusColor()"
            :text="item.status.name"
          ></chip-status-component>
        </v-col>
      </v-row>

    </v-container>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import ChipStatusComponent from "../../ChipStatus";

export default {
  name: "ListItemDesktop",
  components: { ChipStatusComponent },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
  }),
  mounted() {
  },
  computed: {
    ...mapGetters('choiceCurrencies', ['getSelectedItem'])
  },
  watch: {
  },
  methods: {
    ...mapMutations('deposits', ['setSelectedItemById', 'setShowBlockName']),

    setIdx(itemId) {
      this.setSelectedItemById(itemId)
      this.setShowBlockName('itemShow')
    }
  }
}
</script>

<style lang="scss" scoped>

  .s-deposit-item {
    padding: 5px 10px;
    background-color: #ffffff;
    border-radius: 4px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .s-top-line {
      color: #323232;
      display: flex;
      justify-content: space-between;

      .s-date-line {
        color: $g-color-black-main;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .s-body {
      color: #323232;

      .s-title {
        display: flex;
        align-items: center;
      }

      .s-crypto-name {
        color: $g-color-black-main;
        font-size: 16px;
        font-weight: 500;
      }
      .s-crypto-sum {
        color: $g-color-gray;
        font-weight: 400;
        font-size: 14px;
      }
      .s-x {
        color: $g-color-black-main;
        font-size: 14px;
        font-weight: 400;
      }
      .s-top-sum {
        color: $g-color-black-main;
        font-size: 16px;
        font-weight: 400;
      }
      .s-percent {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
</style>
