import Deposit from "../../entities/Deposit";

export default {
    namespaced: true,
    state: {
        items: [],
        selectedIdx: null,
        selectedItem: null,
        showBlockName: null
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload
        },
        resetItems(state) {
            state.items = []
        },
        setSelectedIdx(state, payload) {
            state.selectedIdx = payload
        },
        setSelectedItemById(state, id) {
            state.selectedItem = state.items.find(item => {
                if (id === item.id) {
                    return item
                }
            })
        },
        resetSelectedItem(state) {
            state.selectedItem = null
        },
        setShowBlockName(state, name) {
            state.showBlockName = name
        }
    },
    getters: {
        getItems(state) {
            return state.items
        },
        getSelectedIdx(state) {
            return state.selectedIdx
        },
        getSelectedItem(state) {
            return state.selectedItem
        },
        getItemById: (state) => (id) => {
            return state.items.find(item => item.id === id)
        },
        getShowBlockName(state) {
            return state.showBlockName
        }
    },
    actions: {
        async loadAll ({commit}, briefCaseId) {

            const items = []
            const url = '/deposits?id=' + briefCaseId

            try {
                const response = await axios.get(url);
                const collection = response.data.data;

                Object.keys(collection).forEach(key => {
                    items.push(Deposit.fill(collection[key]))
                })

                commit('resetItems')
                commit('setItems', items)
            } catch (error) {
                // commit('setError', error.message, {root: true})
                throw error
            }
        },
        async create({commit, dispatch, rootGetters}, data) {
            const url = '/deposits'
            let briefCase = rootGetters['briefCases/getSelectedItem']

            try {
                const response = await axios.post(url, data);
                let responseData = response.data;

                if (responseData.status === 'ok') {
                    await dispatch('loadAll', briefCase.id)

                    return responseData
                }
            } catch (error) {
                throw error
            }

            return null
        },
        async prolong({dispatch, rootGetters}, data) {
            const url = 'deposits/prolong'
            let briefCase = rootGetters['briefCases/getSelectedItem']

            try {
                const response = await axios.post(url, data);
                let responseData = response.data;
                console.log('response data', responseData)

                if (responseData.status === 'ok') {
                    await dispatch('loadAll', briefCase.id)

                    return responseData
                }
            } catch (error) {
                throw error
            }

            return null
        }
    }
}
