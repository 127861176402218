window.axios = require('axios');

import {success, error} from "./global/global"

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
window.axios.defaults.baseURL = process.env.VUE_APP_DOMAIN_NAME + process.env.VUE_APP_DOMAIN_URI
window.axios.interceptors.response.use(success, error)

