<template>
<div>
  <v-container class="s-page-title">Обменные операции</v-container>


  <v-progress-circular
    v-if="loadingItems"
    indeterminate
    color="amber"
  ></v-progress-circular>
  <div v-else-if="getShowBlockName === 'empty'">
    <EmptyPage />
  </div>
  <div v-else-if="getShowBlockName === 'list'">
    <ListPage />
  </div>
  <div v-else-if="getShowBlockName === 'create'">
    <CreatePage />
  </div>

</div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import EmptyPage from "../../components/account/exchanger/EmptyPage";
import ListPage from "../../components/account/exchanger/ListPage";
import CreatePage from "../../components/account/exchanger/CreatePage";

export default {
  name: "Exchanger",
  components: {
    EmptyPage,
    ListPage,
    CreatePage
  },
  data: () => ({
    loadingItems: false
  }),
  computed: {
    ...mapGetters('exchangerOperations', ['getShowBlockName', 'getItems'])
  },
  async mounted() {
    await this.loadItems()
  },

  methods: {
    ...mapActions('exchangerOperations', ['loadAll']),
    ...mapMutations('exchangerOperations', ['setShowBlockName']),

    async loadItems() {
      this.loadingItems = true

      await this.loadAll()

      this.checkLoadItems()
      this.loadingItems = false
    },
    checkLoadItems() {
      let name = this.getItems.length > 0 ? 'list' : 'empty'
      this.setShowBlockName(name)
    }
  }
}
</script>

<style lang="scss" scoped>
.s-page-title {
  font-weight: 600;
  font-size: 18px;
}
</style>
