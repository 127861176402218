<template>
<div class="text-center s-empty-page">
  <v-icon class="s-icon">mdi-sync-circle</v-icon>
  <div class="s-desc">
    <span class="text-no-wrap">Необходимо создать</span>
    &nbsp;
    <span class="text-no-wrap">заявку на обмен.</span>
  </div>
  <div class="s-ask">Создать?</div>
  <v-btn
    fab
    small
    color="#D1B986"
    class="mt-2"
    @click="setShowBlockName('create')"
  >
    <v-icon large>
      mdi-plus
    </v-icon>
  </v-btn>
</div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: "EmptyPage",
  methods: {
    ...mapMutations('exchangerOperations', ['setShowBlockName']),

  }
}
</script>

<style lang="scss" scoped>
.s-empty-page {
  margin-top: 150px;
}
.s-icon {
  font-size: 128px;
  color: #6A6A6A;
}
.s-desc {
  font-weight: 400;
  font-size: 18px;
}
.s-ask {
  font-weight: 600;
  font-size: 18px;
  color: $g-color-gold;
}
.s-absolute-btn {
  position: fixed;
  bottom: 130px;
  right: 30px;

  @media screen and (max-width: 960px) {
    bottom: 20px;
    right: 20px;
  }
}
</style>
