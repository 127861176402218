<template>
<div>
  <v-form
    v-if="userName"
    style="max-width: 400px"
    ref="form"
    class="mt-3 ma-auto"
    v-model="formValid"
  >
    <div class="text-center">

      <v-avatar
        class="s-avatar"
        size="140"
        v-if="!avatarImg"
        @click="openAvatarFileDialog()"
      >
        <div
          class="s-avatar-icon-wrapper"
        >
          <v-icon large class="s-avatar-icon">
            mdi-camera-plus-outline
          </v-icon>

          <div class="s-avatar-text">Загрузить<br>фото</div>
        </div>
      </v-avatar>

      <v-hover
        v-if="avatarImg"
        v-slot="{ hover }"
      >
        <v-avatar
          size="140"
        >
          <v-img
            :src="avatarImg"
          >
            <v-scroll-y-transition>
              <div
                v-if="hover"
                class="d-flex justify-space-around align-center"
                style="width: 100%;"
              >
                <v-btn
                  fab
                  small
                  @click="openAvatarFileDialog()"
                >
                  <v-icon>mdi-image-search-outline</v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  @click="deleteAvatar()"
                >
                  <v-icon dark color="red">mdi-delete-forever</v-icon>
                </v-btn>
              </div>
            </v-scroll-y-transition>
          </v-img>

        </v-avatar>
      </v-hover>
    </div>


    <v-file-input
      class="d-none"
      ref="avatarUploader"
      accept="image/*"
      v-model="avatarFile"
      @change="avatarFileChange($event)"
    ></v-file-input>

    <div class="s-label">Имя</div>
    <v-text-field
      solo
      background-color="white"
      label="Введите ваше имя"
      :rules="requiredFieldRules"
      v-model="firstName"
    ></v-text-field>

    <div class="s-label">Фамилия</div>
    <v-text-field
      solo
      background-color="white"
      label="Введите вашу фамилию"
      v-model="lastName"
    ></v-text-field>

    <div class="s-label">Ваш E-mail</div>
    <v-text-field
      solo
      background-color="white"
      label="Введите ваш e-mail"
      :rules="emailRules"
      v-model="email"
    ></v-text-field>

    <div class="s-label">Ваш номер телефона</div>
    <div class="s-phone-btn-link">
      <v-text-field
        solo
        ref="phone"
        background-color="white"
        label="+7"
        v-mask="'+7 (###) ###-##-##'"
        :rules="phoneRules"
        v-model="phone"
      >
      </v-text-field>
      <v-btn
        text
        small
        v-if="visibilitySmsCodeBtn"
        :loading="loadingSmsCodeBtn"
        @click.prevent="getSmsCode"
      >
        Подтвердить
      </v-btn>
    </div>

    <div v-if="visibilitySmsCodeField" class="s-label">SMS-код</div>
    <v-text-field
      v-if="visibilitySmsCodeField"
      solo
      background-color="white"
      label="Введите код из SMS"
      v-mask="'##########'"
      :rules="smsCodeRules"
      v-model="smsCode"
    ></v-text-field>

    <div class="s-passport-label">Подтверждение личности</div>
    <div class="d-flex align-center">
      <v-avatar
        v-if="!passportImg"
        rounded
        size="85"
        class="s-passport-avatar mr-5"
        @click="openPassportFileDialog()"
      >
        <v-icon
          class="s-passport-avatar-icon"
        >
          mdi-camera-plus-outline
        </v-icon>
      </v-avatar>

      <v-hover
        v-if="passportImg"
        v-slot="{ hover }"
      >
        <v-avatar
          rounded
          size="85"
          class="mr-5"
        >
          <v-img
            :src="passportImg"
          >
            <v-scroll-y-transition>
              <div
                v-if="hover"
                class="d-flex justify-space-around align-center"
                style="width: 100%"
              >
                <v-btn
                  fab
                  small
                  @click="openPassportFileDialog()"
                >
                  <v-icon>mdi-image-search-outline</v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  @click="deletePassport()"
                >
                  <v-icon dark color="red">mdi-delete-forever</v-icon>
                </v-btn>
              </div>
            </v-scroll-y-transition>
          </v-img>
        </v-avatar>
      </v-hover>
      <div class="s-passport-text">
        * Прикрипите фото вашего лица на фоне паспорта.
      </div>
    </div>

    <v-file-input
      class="d-none"
      ref="passportUploader"
      accept="image/*"
      v-model="passportFile"
      @change="passportFileChange($event)"
    ></v-file-input>

    <v-checkbox
      dark
      :rules="[v => !!v || 'Вам нужно принять условие']"
      v-model="agreementTerms"
    >
      <template v-slot:label>
        <div class="s-accept-terms-text">Я, даю согласие на обработку своих персональных данных.</div>
      </template>
    </v-checkbox>

    <v-btn
      x-large
      block
      color="#D1B986"
      class="mt-2"
      :loading="loadingSaveData"
      @click="saveData"
    >Сохранить</v-btn>
  </v-form>
</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "Profile",
  data: () => ({
    user: null,
    formValid: false,
    avatarImg: null,
    avatarFile: null,
    passportImg: null,
    passportFile: null,
    firstName: '',
    lastName: '',
    email: null,
    phone: null,
    smsCode: null,
    smsKey: 'qwerty',
    agreementTerms: null,
    visibilitySmsCodeBtn: false,
    visibilitySmsCodeField: false,
    loadingSmsCodeBtn: false,
    loadingSaveData: false,
    requiredFieldRules: [
      v => !!v || 'Обязательное поле',
    ],
    emailRules: [
      v => !!v || 'Обязательное поле',
      v => /.+@.+\..+/.test(v) || 'Не корректные данные',
    ],
    phoneRules: [
      v => !!v || 'Обязательное поле',
      v => (v && v.length === 18) || 'Не корректные данные',
    ],
    smsCodeRules: [
      v => !!v || 'Обязательное поле',
      v => (!v || /^\d+$/.test(v)) || 'Не корректные данные',
    ],
  }),
  mounted() {
    this.initFields()
  },
  computed: {
    ...mapGetters('auth', ['getUser', 'userName'])
  },
  watch: {
    getUser(val) {
      this.initFields()
    },
    phone() {
      if (this.comparePhone()) {
        this.visibilitySmsCodeField = false
      }

      this.visibilitySmsCodeBtn = !this.comparePhone()
    }
  },
  methods: {
    initFields() {
      let user = this.getUser

      if (!user) {
        return
      }

      this.firstName = user.first_name
      this.lastName = user.last_name
      this.email = user.email
      this.phone = user.phone_number
      this.avatarImg = user.avatar
      this.passportImg = user.passport

      // Нужно для обновления маски
      if (this.$refs.phone) {
        this.$nextTick(() => {
          this.$refs.phone.$emit('input')
        })
      }
    },
    async getSmsCode() {
      if (!this.phone || this.phone.length !== 18) {
        await this.$store.dispatch('snackBar/run', 'Укажите номер телефона')
        return
      }

      this.visibilitySmsCodeField = true
      this.loadingSmsCodeBtn = true
      const response = await axios.post('auth/sms-code', {phone: this.phone});
      this.loadingSmsCodeBtn = false

      if (response.data.status !== 'ok') {
        return
      }

      await this.$store.dispatch('snackBar/run', 'На указанный номер телефона отправлен код подтверждения')

      this.smsKey = response.data.data.key
      this.visibilitySmsCodeBtn = false

      setTimeout(() => {
        this.visibilitySmsCodeBtn = true
      }, 30000);
    },
    openAvatarFileDialog() {
      this.$refs.avatarUploader.$refs.input.click()
    },
    openPassportFileDialog() {
      this.$refs.passportUploader.$refs.input.click()
    },
    deleteAvatar() {
      this.avatarImg = null
      this.avatarFile = null
    },
    deletePassport() {
      this.passportImg = null
      this.passportFile = null
    },
    avatarFileChange(image) {
      this.localLoadImage(image, (file) => {
        this.avatarImg = file
      });
    },
    passportFileChange(image) {
      this.localLoadImage(image, (file) => {
        this.passportImg = file
      });
    },
    localLoadImage(image, callback) {
      if (!image) {
        return null;
      }

      let reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = e => {
        callback(e.target.result);
      }
    },
    validate () {
      this.$refs.form.validate()
    },
    async saveData() {
      if (this.loadingSaveData) {
        return
      }

      this.validate()

      if (!this.formValid) {
        return
      }

      if (this.visibilitySmsCodeField === false && !this.comparePhone()) {
        await this.$store.dispatch('snackBar/run', 'Подтвердите номер телефона')
        return
      }

      let res = false;
      let data = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone_number: this.removeSymbolsFromNumber(this.phone),
        smsCode: this.smsCode,
        smsKey: this.smsKey,
        avatar: this.getFileNameFromPath(this.avatarImg),
        passport: this.getFileNameFromPath(this.passportImg)
      }

      let jsonData = JSON.stringify(data);
      let blobData = new Blob([jsonData], {
        type: 'application/json'
      });

      let formObject = new FormData();
      formObject.append('_method', 'PATCH');
      formObject.append('fields', blobData);

      if (this.avatarFile) {
        formObject.append('avatar', this.avatarFile);
      }

      if (this.passportFile) {
        formObject.append('passport', this.passportFile);
      }

      try {
        this.loadingSaveData = true
        res = await this.$store.dispatch('auth/update', formObject)
      }
      catch (e) {
      }

      this.loadingSaveData = false

      if (res) {
        await this.$store.dispatch('snackBar/run', 'Данные обновлены')
      }
    },
    getFileNameFromPath(val) {
      if (!val) {
        return null
      }

      return val.split('\\').pop().split('/').pop()
    },
    removeSymbolsFromNumber(val) {
      if (!val) {
        return val
      }

      val = val.toString().replace(/[^\d]/g, '')

      // return Number.parseInt(val)
      return val
    },
    comparePhone() {
      let newPhone = this.removeSymbolsFromNumber(this.phone)
      let oldPhone = this.getUser.phone_number ? this.getUser.phone_number.toString() : null

      return newPhone === oldPhone
    },
    handlerSmsCodeField() {

    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style lang="scss" scoped>
.s-avatar {
  border: 2px dashed $g-color-gray;
  border-radius: 50%;
  color: $g-color-gray;
  cursor: pointer;

  .s-avatar-icon {
    color: $g-color-gray;
  }
  .s-avatar-text {
    font-weight: 400;
    font-size: 14px;
  }
}

.s-passport-label {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
}
.s-passport-text {
  font-weight: 400;
  font-size: 14px;
}
.s-passport-avatar {
  border: 2px dashed #ffffff;
  border-radius: 10px;
  color: #ffffff;
  margin-right: 15px;
  cursor: pointer;

  .s-passport-avatar-icon {
    color: #ffffff;
  }
}
.s-label {
   font-size: 14px;
   color: $g-color-gold;
   text-align: left;
}

.s-phone-btn-link {
  position: relative;

  button {
    position: absolute;
    right: 5px;
    top: 10px;
    color: $g-color-dark-blue;
  }
}
.s-accept-terms-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
</style>
