<template>
  <div class="pt-3">

    <v-progress-circular
      v-if="loadingDeposits"
      indeterminate
      color="amber"
    ></v-progress-circular>

    <div
      v-if="showBlock === 'itemShow'"
      class="ma-auto"
      style="max-width: 500px"
    >
      <deposit-item-create-component v-if="isNewDeposit()"></deposit-item-create-component>

      <deposit-show-item-component v-else></deposit-show-item-component>
    </div>

    <div v-else-if="showBlock === 'itemList'">

      <ListItemMobile
        v-if="$vuetify.breakpoint.smAndDown"
        v-for="item in getItems" :key="item.id"
        :item="item"
      />

      <ListItemDesktop
        v-if="$vuetify.breakpoint.mdAndUp"
        v-for="itemD in getItems" :key="itemD.id"
        :item="itemD"
      />

      <deposit-empty-component v-if="!getItems.length"></deposit-empty-component>

      <v-btn
        fab
        :small="$vuetify.breakpoint.smAndDown"
        color="#D1B986"
        class="s-absolute-btn"
        @click="showBlockCreateItem"
      >
        <v-icon large>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>


  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'
import DepositEmptyComponent from "./ListEmpty";
import DepositShowItemComponent from "./BlockItem";
import DepositItemCreateComponent from "./BlockItemCreate";
import ListItemMobile from "./ListItemMobile";
import ListItemDesktop from "./ListItemDesktop";

export default {
  name: "DepositBlock",
  props: {
  },
  components: {
    DepositEmptyComponent,
    DepositShowItemComponent,
    DepositItemCreateComponent,
    ListItemMobile,
    ListItemDesktop
  },
  data: () => ({
    dialog: false,
    loadingDeposits: false
  }),
  async mounted() {
    this.setShowBlockName(null)
    this.loadingDeposits = true
    await this.loadAll(this.getBriefCaseId())
    this.loadingDeposits = false

    this.setShowBlockName('itemList')
  },
  computed: {
    ...mapGetters('briefCases', {
      getSelectedBriefCase: 'getSelectedItem',
    }),
    ...mapGetters('deposits', [
      'getItems',
      'getSelectedIdx',
      'getSelectedItem'
    ]),
    ...mapGetters('deposits', {
      showBlock: 'getShowBlockName'
    })
  },
  watch: {
    async getSelectedBriefCase(item) {
      this.setShowBlockName(null)
      this.loadingDeposits = true

      await this.loadAll(item.id)

      this.loadingDeposits = false
      this.setShowBlockName('itemList')
    },
  },
  methods: {
    ...mapActions('deposits', ['loadAll']),
    ...mapMutations('deposits', ['resetSelectedItem', 'setShowBlockName']),

    getBriefCaseId() {
      if (!this.getSelectedBriefCase.id) {
        return
      }

      return this.getSelectedBriefCase.id
    },
    showBlockCreateItem() {
      this.resetSelectedItem()
      this.setShowBlockName('itemShow')
    },
    isNewDeposit() {
      if (!this.getSelectedItem) {
        return true
      }

      const status = this.getSelectedItem.status.id
      const startedAt = this.getSelectedItem.started_at

      return status === 1 && startedAt === null;
    }
  }
}
</script>

<style lang="scss" scoped>
.s-absolute-btn {
  position: fixed;
  bottom: 130px;
  right: 30px;

  @media screen and (max-width: 960px) {
    bottom: 20px;
    right: 20px;
  }
}
</style>
