import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

import Home from '../views/MainPage.vue'
import AboutFund from '../views/pages/AboutFund'
import Investment from '../views/pages/InvestmentConditions'
import Security from '../views/pages/Security'
import Contacts from '../views/pages/Contacts'
import Login from '../views/pages/Login'
import Register from '../views/pages/Register'
import PageNotFound from '../views/PageNotFound'

import Deposits from '../views/account/Deposits'
import Profile from '../views/account/Profile'
import Exchanger from '../views/account/Exchanger'

Vue.use(VueRouter)

const routes = [
  { path: "*", component: PageNotFound },
  {
    path: '/',
    name: 'home',
    meta: { layout: 'main-page'},
    component: Home
  },
  {
    path: '/about_fund',
    name: 'about_fund',
    meta: { layout: 'pages', title: 'О фонде'},
    component: AboutFund
  },
  {
    path: '/investment',
    name: 'investment',
    meta: { layout: 'pages', title: 'Условия инвестирования'},
    component: Investment
  },
  {
    path: '/security',
    name: 'security',
    meta: { layout: 'pages', title: 'Безопасность'},
    component: Security
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: { layout: 'pages', title: 'Контакты'},
    // meta: { layout: 'account'},
    component: Contacts
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/pages/AboutFund.vue')
  },
  {
    path: '/entry',
    name: 'login',
    meta: {
      layout: 'pages',
      hideForAuth: true,
      title: 'Вход'
    },
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      layout: 'pages',
      hideForAuth: true,
      title: 'Создать аккаунт'
    },
    component: Register
  },
  {
    path: '/account/deposits',
    name: 'account.deposits',
    meta: {
      layout: 'account',
      auth: true,
      title: 'Депозиты'
    },
    component: Deposits,
  },
  {
    path: '/account/profile',
    name: 'account.profile',
    meta: {
      layout: 'account',
      auth: true,
      title: 'Мой профиль'
    },
    component: Profile,
  },
  {
    path: '/account/exchanger',
    name: 'account.exchanger',
    meta: {
      layout: 'account',
      auth: true,
      title: 'Обменник'
    },
    component: Exchanger,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn']
  const requireAuth = to.matched.some(record => record.meta.auth)
  const hideForAuth = to.matched.some(record => record.meta.hideForAuth)

  if (requireAuth && !isLoggedIn) {
    next('/entry')
  }
  else if (hideForAuth && isLoggedIn) {
    next({ path: '/account/deposits' });
  }
  else {
    next()
  }
})

export default router
