<template>
  <div class="s-block-header d-none d-md-flex justify-center align-end">
    <div class="s-header__title">{{ $route.meta.title }}</div>
  </div>
</template>

<script>
export default {
  name: "HeaderTopLine"
}
</script>

<style lang="scss" scoped>

.s-block-header {
  background-image:
    linear-gradient(0deg, rgba(0, 18, 66, 0.85), rgba(0, 18, 66, 0.85)),
    url("/images/background_main.jpg");
  background-size: cover;
  background-color: #090842;
  background-position: top center;
  margin: -76px -12px 0px;
  height: 160px;

.s-header__title {
  font-weight: 400;
  font-size: 64px;
}

}
</style>
