<template>
<div>
  <ListItemMobile
    v-if="$vuetify.breakpoint.smAndDown"
    v-for="item in getItems" :key="item.id"

    :item="item"
  />

  <ListItemDesktop
    v-if="$vuetify.breakpoint.mdAndUp"
    v-for="itemD in getItems" :key="itemD.id"

    :item="itemD"
  />

  <v-btn
    fab
    :small="$vuetify.breakpoint.smAndDown"
    color="#D1B986"
    class="s-absolute-btn"
    @click="showBlockCreateItem"
  >
    <v-icon large>
      mdi-plus
    </v-icon>
  </v-btn>
</div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import ListItemMobile from "./ListItemMobile";
import ListItemDesktop from "./ListItemDesktop";

export default {
  name: "ListPage",
  components: {
    ListItemMobile,
    ListItemDesktop
  },
  computed: {
    ...mapGetters('exchangerOperations', ['getItems'])
  },
  methods: {
    ...mapMutations('exchangerOperations', ['setShowBlockName']),

    showBlockCreateItem() {
      this.setShowBlockName('create')
    }
  }
}
</script>

<style lang="scss" scoped>

.s-absolute-btn {
  position: fixed;
  bottom: 130px;
  right: 30px;

  @media screen and (max-width: 960px) {
    bottom: 20px;
    right: 20px;
  }
}
</style>
