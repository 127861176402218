import BaseEntity from "./BaseEntity";

class Deposit extends BaseEntity {

  constructor() {
    super();

    this.crypto_sum = null
    this.term = null
    this.status = {
      id: null
    }
    this.currency = {
      crypto_id: null
    }
  }

  getStatusColor() {
    switch (this.status.id) {
      case 1:
      case 3:
        return '#949494'

      case 2:
        return '#00AE26'

      default:
        return 'white'
    }
  }
  getCoursePercentSum(currencyKey) {
    if (!this.course_percent) {
      return 0
    }

    return this._addPlus(this.course_percent[currencyKey])
  }

  getCoursePercentColor(currencyKey) {
    if (!this.course_percent[currencyKey]) {
      return null
    }

    return this._getPercentColor(this.course_percent[currencyKey])
  }
  getIncomePercentSum() {
    if (!this.income_percent) {
      return 0
    }

    return this._addPlus(this.income_percent)
  }

  getIncomePercentColor() {
    if (!this.income_percent) {
      return null
    }

    return this._getPercentColor(this.income_percent)
  }

  _getPercentColor(val) {
    let color = null

    if (val > 0) {
      color = '#00AB3A'
    }
    else if (val < 0) {
      color = '#EA0000'
    }

    return {
      color
    }
  }

  _addPlus(val) {
    return val > 0 ? '+' + val : val
  }
}

export default Deposit;
