import store from '../../store'
import router from '../../router'

let success = (response) => {

  if (response.data.errors) {
    let str = createErrorStr(response)
    store.dispatch('snackBar/run', str)
  }
  else if (response.data.status === 'fail') {
    let message = response.data.msg || 'some error'
    store.dispatch('snackBar/run', message)
  }

  return response
}
let error = (error) => {
  if (error.response.status === 401) {
    store.dispatch('auth/logoutClient')
    router.push('/entry')
  }

  if (error.response.status === 422) {
    let str = createErrorStr(error.response)
    store.dispatch('snackBar/run', str)
  }

  if (error.response.status === 500) {
    store.dispatch('snackBar/run', 'Ошибка сервера')
  }

  throw error;
}

let createErrorStr = (response) => {
  const errors = response.data.errors
  let str = response.data.message || ''

  for (let key in errors) {
    for (let error of errors[key]) {
      str = str + "<br>" + error
    }
  }

  return str;
}

export {success, error}
