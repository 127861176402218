import ExchangerOperation from "../../entities/ExchangerOperation";

export default {
    namespaced: true,
    state: {
        items: [],
        showBlockName: ''
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload
        },
        resetItems(state) {
            state.items = []
        },
        setShowBlockName(state, name) {
            state.showBlockName = name
        }
    },
    getters: {
        getItems(state) {
            return state.items
        },
        getShowBlockName(state) {
            return state.showBlockName
        }
    },
    actions: {
        async loadAll ({commit}) {

            const items = []
            const url = '/exchanger_operations'

            try {
                const response = await axios.get(url);
                const collection = response.data.data;

                Object.keys(collection).forEach(key => {
                    items.push(ExchangerOperation.fill(collection[key]))
                })

                commit('resetItems')
                commit('setItems', items)
            } catch (error) {
                // commit('setError', error.message, {root: true})
                throw error
            }
        },
        async create({dispatch}, data) {
            const url = '/exchanger_operations'

            try {
                const response = await axios.post(url, data);
                let responseData = response.data;

                if (responseData.status === 'ok') {
                    await dispatch('loadAll')

                    return responseData
                }
            } catch (error) {
                throw error
            }

            return null
        }
    }
}
